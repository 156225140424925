import styled from 'styled-components';

const FormWrapper = styled.section`
  padding: 30px 0 400px;
  .inputWrapper {
    @media (max-width: 480px) {
      margin: 0 20px 0 20px;
    }
    margin: 0 30% 0 30%;
    input {
      margin: 10px 0 10px 0;
    }
  }
  button {
    //style button better
    margin: 10px 0 0 0;
    alignself: center;
  }
`;
export { FormWrapper };
