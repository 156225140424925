import React from 'react';
import { FormWrapper } from './form.style';
import Input from 'common/src/components/Input';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';

const Form = ({ title, description }) => {
  return (
    <FormWrapper id="contact-us">
      <FeatureBlock
        title={<Heading {...title} />}
        description={<Text {...description} />}
      />
      <div className="inputWrapper">
        <form
          name="contact-us"
          action="/success"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact-us" />
          <Input label="Name" name="Name" inputType="text" value="string" />
          <Input label="Email" name="Email" inputType="email" value="string" />
          <Input
            label="Message"
            name="Message"
            inputType="textarea"
            value="string"
          />
          <Button title="Submit" type="submit" />
        </form>
      </div>
    </FormWrapper>
  );
};

Form.defaultProps = {
  title: {
    content: 'Contact Us',
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },
  description: {
    content:
      'We are a small team, but we will get back to you as quick as we can!',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    textAlign: ['center', 'center'],
  },
};

export default Form;
